import React from 'react';
import { Box, Container, styled } from '@mui/material';

const Section = ({ children, colorish = false, maxWidth = 'lg', ...rest }) => (
  <StyledSection component='section' colorish={colorish ? 1 : 0} {...rest}>
    <Container maxWidth={maxWidth}>{children}</Container>
  </StyledSection>
);

export default Section;

const StyledSection = styled(Box)(({ theme, colorish }) => ({
  padding: theme.spacing(15, 5),
  backgroundColor: colorish ? theme.palette.secondary.light : theme.palette.common.white,

  '&:first-of-type': {
    padding: theme.spacing(5),
  },

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(5, 1),

    '&:first-of-type': {
      padding: theme.spacing(10, 1, 5),
    },
  },
}));
