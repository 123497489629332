import React from 'react';
import { Button, styled } from '@mui/material';

const ButtonWrapper = ({ children, loading, variant = 'primary', ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <ButtonPrimary variant='contained' {...rest}>
          {children}
        </ButtonPrimary>
      );

    case 'secondary':
      return (
        <ButtonBase variant='outlined' {...rest}>
          {children}
        </ButtonBase>
      );

    default:
      return (
        <ButtonBase loading={loading ? 1 : 0} {...rest}>
          {children}
        </ButtonBase>
      );
  }
};

export default ButtonWrapper;

const ButtonBase = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.2, 3.5),
  boxShadow: 'none',

  '&:hover': {
    boxShadow: 'none',
  },
}));

const ButtonPrimary = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.common.white,
}));
