import React, { useState, useEffect } from 'react';
import { Fade } from '@mui/material';

const FadingSlider = ({ children, maxSlides, slideDuration = 5 }) => {
  const [slideNum, setSlideNum] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => setSlideNum(prevSlideNum => (prevSlideNum + 1) % maxSlides),
      slideDuration * 1000
    );

    return () => clearInterval(interval);
  }, [maxSlides, slideDuration]);

  return (
    <Fade key={slideNum} in={true} timeout={1000}>
      <div>{children[slideNum]}</div>
    </Fade>
  );
};

export default FadingSlider;
