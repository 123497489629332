import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    secondary: {
      light: '#faf8f1',
      main: '#434766',
    },
    primary: {
      main: '#d0bd84',
    },
  },

  // https://stackoverflow.com/questions/52472372/responsive-typography-in-mui
  typography: {
    allVariants: {
      fontFamily: `'Lato', sans-serif`,
    },
  },

  border: {},

  borderRadius: {
    small: '1rem',
    medium: '3rem',
    large: '10rem',
  },

  boxShadow: {
    small: '0 .5rem 1rem rgba(0,0,0,0.2)',
    huge: '22px 15px 6px #00000029',
  },

  utils: {},
});

export default theme;
