import { styled } from '@mui/material';

const Quote = styled('blockquote')(({ theme, variant = 'secondary' }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette[variant].main,
  color: variant === 'secondary' ? theme.palette.primary.main : theme.palette.secondary.main,
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.shadows[10],
}));

export default Quote;
