import React, { useState } from 'react';
import { Link } from 'gatsby';
import {
  AppBar,
  Stack,
  styled,
  Typography,
  IconButton,
  Box,
  List,
  Drawer,
  ListItem,
} from '@mui/material';

import { navigationLinks } from 'utils/data';
import { Icon } from 'ui';
import { NavLinkAnimator, Slide } from 'components/animations';
import logoImg from 'images/logo.png';

const Navigation = () => {
  const [openMobileNav, setOpenMobileNav] = useState();
  const toggleMobileNav = visibility => () => setOpenMobileNav(visibility);

  return (
    <>
      <NavBar position='fixed'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Slide direction='left'>
            <Stack direction='row' alignItems='center' gap={2}>
              <Link to='/'>
                <Logo src={logoImg} alt='LOGO' />
              </Link>
              <Typography
                variant='subtitle2'
                color='primary.main'
                textAlign='center'
                mt={-1}
                display={{ xs: 'none', md: 'block' }}>
                your wealth, your way
              </Typography>
            </Stack>
          </Slide>

          <NavLinksContainer gap={3} direction='row'>
            {navigationLinks.map((navLink, idx) => (
              <NavLinkAnimator key={idx} delay={idx * 0.1}>
                <NavLink
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  partiallyActive={navLink.href === '/blog'}>
                  <Typography variant='body1'>{navLink.text}</Typography>
                </NavLink>
              </NavLinkAnimator>
            ))}
          </NavLinksContainer>

          <Hamburger onClick={toggleMobileNav(true)}>
            <Icon name='Menu' color='primary.main' />
          </Hamburger>
        </Stack>
      </NavBar>

      <Drawer open={openMobileNav} anchor='top' onClose={toggleMobileNav(false)}>
        <MobileNav>
          <Stack alignItems='flex-end' px={2}>
            <IconButton onClick={toggleMobileNav(false)}>
              <Icon name='X' color='primary.main' />
            </IconButton>
          </Stack>

          <List>
            {navigationLinks.map((navLink, idx) => (
              <MobileNavItem key={idx}>
                <Link
                  to={navLink.href}
                  target={navLink.target || '_self'}
                  activeClassName='nav-link-active'
                  onClick={toggleMobileNav(false)}>
                  {navLink.text}
                </Link>
              </MobileNavItem>
            ))}
          </List>
        </MobileNav>
      </Drawer>
    </>
  );
};

export default Navigation;

const NavBar = styled(AppBar)(({ theme }) => ({
  padding: `${theme.spacing(2, 5)} !important`,
  textTransform: 'capitalize',
  backgroundColor: theme.palette.secondary.main,
  boxShadow: '0 1.5rem 3rem -.75rem rgba(0, 0, 0, .25)',

  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2, 3)} !important`,
  },
}));

const Logo = styled('img')({
  height: '3rem',
  width: '10rem',
  objectFit: 'contain',
});

const NavLinksContainer = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavLink = styled(Link)(({ theme }) => ({
  position: 'relative',
  color: theme.palette.primary.main,

  '&::before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.primary.main,
    bottom: '-.4rem',
    transform: 'scaleX(0)',
    transition: 'all .4s',
    transformOrigin: 'left',
  },

  '&:hover::before': {
    transform: 'scaleX(1)',
  },
}));

const Hamburger = styled(IconButton)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const MobileNav = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(2, 0),
}));

const MobileNavItem = styled(ListItem)({
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'capitalize',
});
