import React from 'react';
import { motion } from 'framer-motion';

export const Slide = ({ direction = 'bottom', delay = 0, children, ...rest }) => {
  let initial, whileInView;

  switch (direction) {
    case 'top':
      initial = { opacity: 0, y: -100 };
      whileInView = { opacity: 1, y: 0 };
      break;

    case 'right':
      initial = { opacity: 0, x: 100 };
      whileInView = { opacity: 1, x: 0 };
      break;

    case 'bottom':
      initial = { opacity: 0, y: 100 };
      whileInView = { opacity: 1, y: 0 };
      break;

    default:
      initial = { opacity: 0, x: -100 };
      whileInView = { opacity: 1, x: 0 };
  }

  return (
    <motion.div
      {...rest}
      initial={initial}
      whileInView={whileInView}
      viewport={{ once: true }}
      transition={{ duration: 0.5, type: 'spring', stiffness: 100, delay }}>
      {children}
    </motion.div>
  );
};

export const NavLinkAnimator = ({ children, delay, ...rest }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, type: 'tween', delay }}
    {...rest}>
    {children}
  </motion.div>
);
