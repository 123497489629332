import React from 'react';
import { Typography, styled } from '@mui/material';

const TypographyWrapper = ({ children, variant = 'primary', ...rest }) => {
  switch (variant) {
    case 'primary':
      return (
        <TypographyPrimary variant='h3' {...rest}>
          {children}
        </TypographyPrimary>
      );

    case 'secondary':
      return (
        <Typography variant='h4' {...rest}>
          {children}
        </Typography>
      );

    default:
      return (
        <TypographyBase variant='h6' {...rest}>
          {children}
        </TypographyBase>
      );
  }
};

export default TypographyWrapper;

const TypographyBase = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'capitalize',
}));

const TypographyPrimary = styled(TypographyBase)(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(6),
  backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
  color: 'transparent',
  WebkitBackgroundClip: 'text',

  '&::before, &::after': {
    content: '""',
    display: 'inline-block',
    borderRadius: '1rem',
    height: '2px',
    width: '5rem',
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));
