import slide1Img from 'images/intro/slide-1.png';
import slide2Img from 'images/intro/slide-2.png';
import slide3Img from 'images/intro/slide-3.png';

import recognize1Img from 'images/cartoons/recognize-1.png';
import recognize2Img from 'images/cartoons/recognize-2.png';
import recognize3Img from 'images/cartoons/recognize-3.png';

import wealthWiring1Img from 'images/wealth-wiring/wealth-1.png';
import wealthWiring2Img from 'images/wealth-wiring/wealth-2.png';
import wealthWiring3Img from 'images/wealth-wiring/wealth-3.png';

export const navigationLinks = [
  {
    text: 'home',
    href: '/',
  },
  {
    text: 'offerings',
    href: '/offerings',
  },
  {
    text: 'blog',
    href: '/blog',
  },
  {
    text: 'contact',
    href: '/contact',
  },
];

export const introSlides = [
  {
    text: "Hello, I'm Angela, and I am so happy that you are here. My passion is inspiring powerful women just like you",
    image: slide1Img,
  },
  {
    text: 'It is my mission to empower you to become your most powerful self so you can achieve the success that you deserve.',
    image: slide2Img,
  },
  {
    text: 'It is time to give yourself the love and the respect that you truly deserve and be the most powerful version of who you are meant to be.',
    image: slide3Img,
  },
];

export const recognition = [
  {
    text: 'Are you a woman who recognizes her immense potential to create greater wealth, time, freedom, and impact? If so, I offer my assistance, whether you seek to escape financial struggle and debt, elevate your income, or manifest the life you truly deserve.',
    image: recognize1Img,
  },
  {
    text: "I can guide you in transforming your relationship with money, enabling you to cultivate financial security while maintaining your desired lifestyle. Even if you've exhausted various approaches before, I firmly believe that your entire life can be positively impacted by shifting your energy, emotions, and mindset concerning money.",
    image: recognize2Img,
  },
  {
    text: 'I am committed to working with you based on unwavering trust. Trust, to me, entails fully embracing your principles and wholeheartedly embodying them. It involves prioritizing integrity and fostering an environment of openness, honesty, and direct communication between us.',
    image: recognize3Img,
  },
];

export const wealthWiringSlides = [
  {
    image: wealthWiring1Img,
    text: "Are you a woman ready to unleash your potential for wealth, freedom, and impact? Whether you're aiming to overcome financial struggles, increase your income, or manifest the life you deserve, I'm here to support you.",
  },
  {
    image: wealthWiring2Img,
    text: "I specialize in helping you transform your money mindset and develop a harmonious relationship with wealth. Together, we can create financial security without compromising your desired lifestyle. Even if you've tried different methods in the past",
  },
  {
    image: wealthWiring3Img,
    text: 'This comprehensive 12-week course empowers you to heal past money traumas, remove obstacles hindering the flow of abundance, and set your money-making abilities on autopilot. By doing so, you can effortlessly increase your earnings, retain more wealth, and experience a greater sense of abundance, freeing yourself from financial worries indefinitely.',
  },
];
